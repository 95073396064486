<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
                partner:{},
                penjualan_ayam:{},
                penjualan_pakan:{},
                bulk_kargo:{},
                ceo:{},
                visi:{},
                misi:{},
                heroImage:{},
                employee_team:[],
                valueComp:[],
                achievments:[],
                aboutAchievement:[],
                filter: {skip:0},
                count: 20,
            }
        },
        async mounted() {
            await this.ready();
            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
                $('.slider-caption').css('top','20%')
                $('.slider-caption h2').css('margin-bottom','0')
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetHero()
            this.apiGetStaticContent()
            this.apiGetTeam()
            this.apiGetValue()
            this.apiGetAchievments()
            this.apiGetAchievement()
        },
        methods: {
            apiGetStaticContent(){
                Gen.apirest('/static-aboutus', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.partner=resp.data.partner
                    this.penjualan_ayam=resp.data.penjualan_ayam
                    this.penjualan_pakan=resp.data.penjualan_pakan
                    this.bulk_kargo=resp.data.bulk_kargo
                    this.ceo=resp.data.ceo
                    this.visi=resp.data.visi
                    this.misi=resp.data.misi
                    this.$root.topProgress.done()
                })
            },
            apiGetHero(){
                Gen.apirest('/hero/1', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.heroImage=resp.data
                })
            },
            apiGetTeam(){
                Gen.apirest('/team-kami', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.employee_team=resp.employee_team
                })
            },
            apiGetValue(){
                Gen.apirest('/value-company', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.valueComp=resp.data
                })
            },
            apiGetAchievement(){
                Gen.apirest('/about-achievement', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.aboutAchievement=resp.data
                })
            },
            apiGetAchievments(){
                Gen.apirest('/achievments', {skip:this.filter.skip}, (err, resp)=>{
                    if(err) console.log(err)
                    this.achievments=this.achievments.concat(resp.data.achievments)
                    this.count = resp.count
                })
            },
            clickContinue(){
                this.filter.skip=this.achievments.length
                this.apiGetAchievments()
            }
        }
    };
</script>
<template>
    <div Content>
        <Header></Header>        
        <section id="slider" class="slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
            <!-- slider-parallax-inner -->
            <div class="paralaxActivities">
                <div class="swiper-container swiper-parent">
                    <div v-if="!isMobile" class="swiper-wrapper w-md-100" :style="'background-image: url(\''+uploader(heroImage.mhi_image,'1349x350')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption dark go-mid">
                                <h2><span class="txt_red">Farmsco</span> is Different</h2>
                                <p class="nomargin">{{ heroImage.mhi_desc }}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                    <div v-else class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image,'360x240')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption dark go-mid">
                                <h2><span class="txt_red">Farmsco</span> is Different</h2>
                                <p class="nomargin">{{ heroImage.mhi_desc }}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                </div>
                <a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
            </div>
        </section>
        <section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1 class="d-none d-md-block">&nbsp;</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{ web.mn_tentang }}</li>
				</ol>
			</div>
		</section>
        <section id="content">
            <div class="content-wrap">
                <section class="section notopmargin nobottommargin overview nobg">
                    <div class="container">
                        <div class="heading-block center">
                            <h3>{{partner.msc_title}}</h3>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="wrap_about_def" v-html="partner.msc_desc"></div>
                            </div>
                        </div>
                        <div class="row justify-content-center">

                            <div class="col-sm-6 col-lg-4 col-md-6" v-for="(vA,kA) in aboutAchievement">
                                <div :class="'achieve '+vA.maa_color">
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="ac-img">
                                                <VImg :src="uploader(vA.maa_image, 49)" :alt="vA.maa_title" :title="vA.maa_title"></VImg>
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="ac-desc">
                                                <h4>{{vA.maa_title}}</h4>
                                                <span v-html="vA.maa_desc"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section notopmargin notoppadding  ceo nobottommargin nobottompadding">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-7 col-lg-7 col-md-10">
                                <div class="ceo-words" v-html="ceo.msc_desc"></div>
                            </div>
                            <div class="col-sm-5 col-lg-5 col-md-6 offset-md-6 offset-lg-0">
                                <VImg v-if="!isMobile" :src="uploader(ceo.msc_image, 440)" :alt="ceo.msc_title" :title="ceo.msc_title" class="ceo-photo"></VImg>
                                <VImg v-else :src="uploader(ceo.msc_image, 298)" :alt="ceo.msc_title" :title="ceo.msc_title" class="ceo-photo"></VImg>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section nobg nobottommargin notopmargin">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="vision_mission_wrap">
                                    <h3>{{visi.msc_title}}</h3>
                                    <div v-html="visi.msc_desc"></div>
                                </div>
                                <div class="vision_mission_wrap">
                                    <h3>{{misi.msc_title}}</h3>
                                    <div v-html="misi.msc_desc"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <aside class="wrap_value_list" data-aos="fade-left" v-for="(v3,k3) in valueComp" :key="k3" :data-aos-delay="v3.delay">
                                    <div class="row">
                                        <div class="col-md-3 col-3">
                                            <div :class="'ic_value '+v3.mv_color+''">
                                                <VImg :src="uploader(v3.mv_image, '40')" :alt="v3.mv_title" :title="v3.mv_title"></VImg>
                                            </div>
                                        </div>
                                        <div class="col-9 pl-4 pl-md-3">
                                            <div class="wrap_content_value"><h4 v-html="v3.mv_title"></h4></div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                

                <section v-for="(v,k) in employee_team" :class="v.style">
                    <div class="container">
                        <div class="heading-block center">
                            <h3 v-html="v.team"></h3>
                        </div>
                        <div class="row justify-content-center">
                            <div v-for="(v2,k2) in v.employee" class="col-md-4 col-lg-3 col-6" data-aos="fade-up" :data-aos-delay="(v2.delay+(150*k))">
                                <div class="front-news">
                                    <div class="fn-img">
                                        <VImg v-if="!isMobile" :src="uploader(v2.me_photo, 263)" :alt="v2.me_fullname" :title="v2.me_fullname"></VImg>
                                        <VImg v-else :src="uploader(v2.me_photo, 160)" :alt="v2.me_fullname" :title="v2.me_fullname"></VImg>
                                    </div>
                                    <div class="fn-desc">
                                        <h4 class="fnd-title">{{ v2.me_fullname }}</h4>
                                        <span class="fnd-name">{{ v2.position }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section notopmargin">
                    <div class="heading-block center">
                        <h3>{{ web.lbl_perjalanan_kami }}</h3>
                    </div>
                    <div class="container">
                        <div class="timeline">
                            <div>
                                <div class="tml-container" v-for="(achv,k) in achievments">
                                    <div class="tml-content" :data-animate="(k%2)==1 ? 'fadeInLeft' : 'fadeInRight'">
                                        <h3 class="year">{{achv.ma_year}}</h3>
                                        <VImg v-if="!isMobile" data-aos="fade-up" :src="uploader(achv.ma_image, '480x275')" :alt="achv.ma_title" :title="achv.ma_title" class="tml-img-mobile"></VImg>
                                        <VImg v-else data-aos="fade-up" :src="uploader(achv.ma_image, 300)" :alt="achv.ma_title" :title="achv.ma_title" class="tml-img-mobile"></VImg>
                                        <div style="margin:0px;" v-html="achv.ma_desc"></div>
                                    </div>
                                    <div style="width: auto;" class="tml-content-img" :data-aos="(k%2)==1 ? 'fade-right' : 'fade-left'">
                                        <VImg v-if="!isMobile" :src="uploader(achv.ma_image, '480x275')" :alt="achv.ma_title" :title="achv.ma_title"></VImg>
                                        <VImg v-else :src="uploader(achv.ma_image, 300)" :alt="achv.ma_title" :title="achv.ma_title"></VImg>
                                    </div>
                                </div>   
                                <!-- <button v-if="achievments.length < count" class="btn btn-border_red ml-3" @click="clickContinue()">{{ web.btn_show_more }}</button> -->   
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>